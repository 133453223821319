import React from 'react';
import GradientText from '../common/gradient-text';
import { GatsbyImage } from 'gatsby-plugin-image';
import Fade from '@/components/common/fade';
import { PortableText } from '@portabletext/react';
import DoubleQuote from '@/assets/double-quote.svg';
import { customerStoryBlockText } from './customer-story-text';

const CustomerStoryHighLighted = ({
  blockquote,
  customerRep,
  customerStoryBlocks,
}) => {
  return (
    <div className="relative z-10 mt-12 to-pb py-16 md:mt-16 lg:mt-24 lg:py-24">
      <div className="absolute -left-1/2 -right-1/2 bottom-0 top-0 z-10 bg-gradient-to-br from-y via-rm"></div>
      <div className="relative z-10 mx-auto max-w-[1092px] px-4">
        <div className="-mx-4 flex flex-wrap justify-between">
          <div className="w-full px-4 text-white md:w-1/2 lg:w-1/2">
            <p className="mb-8 w-8 lg:mb-12 lg:w-10 xl:mb-14">
              <DoubleQuote />
            </p>
            <p className="font-inter text-2xl font-bold md:text-3xl lg:text-4xl">
              {blockquote}
              <span className="mb-1.5 inline-block h-3 w-3 -translate-y-full rotate-180 transform">
                <DoubleQuote />
              </span>
            </p>
            <div className="-mx-2 mb-8 mt-8 flex flex-row items-center lg:mb-0">
              <div className="w-auto px-2">
                <GatsbyImage
                  className="h-20 w-20 rounded-full bg-purple-100"
                  image={customerRep.image.asset.gatsbyImageData}
                  alt={customerRep.name}
                />
              </div>
              <div className="w-auto px-2">
                <p className="text-lg font-bold">{customerRep.name}</p>
                <p>{customerRep.role}</p>
              </div>
            </div>
          </div>
          <div className="-mb-32 w-full px-4 md:-mb-64 md:w-1/2 lg:w-5/12">
            {customerStoryBlocks &&
              customerStoryBlocks.map((block, idx) => {
                return (
                  <div
                    className="mb-4 rounded-lg border border-grey-200 bg-white px-5 py-4 text-sm"
                    key={`block-${idx}`}
                  >
                    <Fade>
                      <p className="mb-3">
                        <GradientText>→ {block.name}</GradientText>
                      </p>
                      <div className="text-grey-700">
                        <PortableText
                          value={block._rawBlockDescription}
                          components={customerStoryBlockText as any}
                        />
                      </div>
                    </Fade>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerStoryHighLighted;
