import React from 'react';
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics';

const customerStorySectionText = {
  block: {
    normal: ({ children }) => <p className="mb-3">{children}</p>,
  },
  list: {
    bullet: ({ children }) => (
      <ul className="flex-cols mb-3 flex list-inside list-disc flex-col leading-relaxed">
        {children}
      </ul>
    ),
    number: ({ children }) => (
      <ol className="mb-3 list-inside list-decimal">{children}</ol>
    ),
  },
  listItem: {
    bullet: ({ children }) => <li className="">{children}</li>,
  },
  marks: {
    link: ({ children, value }) => {
      const target = (value?.href || '').startsWith('http')
        ? '_blank'
        : undefined;
      return (
        <OutboundLink
          href={value.href}
          target={target}
          rel={target === '_blank' && 'noindex'}
          className="text-blue-800 underline transition-all duration-300 hover:text-purply-blue"
        >
          {children}
        </OutboundLink>
      );
    },
    strong: ({ children }) => <span className="font-bold">{children}</span>,
    underline: ({ children }) => <span className="underline">{children}</span>,
    em: ({ children }) => <span className="italic">{children}</span>,
    superscript: ({ children }) => <sup>{children}</sup>,
    subscript: ({ children }) => <sub>{children}</sub>,
    highlight: ({ children }) => (
      <span className="bg-yellow-200 px-1 leading-none">{children}</span>
    ),
    'strike-through': ({ children }) => (
      <span className="line-through">{children}</span>
    ),
  },
};

const customerStoryBlockText = {
  block: {
    normal: ({ children }) => <p className="mt-2">{children}</p>,
  },
  list: {
    bullet: ({ children }) => (
      <ul className="flex-cols mt-2 flex list-inside list-disc flex-col leading-relaxed">
        {children}
      </ul>
    ),
    number: ({ children }) => (
      <ol className="mt-2 list-inside list-decimal">{children}</ol>
    ),
  },
  listItem: {
    bullet: ({ children }) => <li className="">{children}</li>,
  },
  marks: {
    link: ({ children, value }) => {
      const target = (value?.href || '').startsWith('http')
        ? '_blank'
        : undefined;
      return (
        <OutboundLink
          href={value.href}
          target={target}
          rel={target === '_blank' && 'noindex'}
          className="text-blue-800 underline transition-all duration-300 hover:text-purply-blue"
        >
          {children}
        </OutboundLink>
      );
    },
    strong: ({ children }) => <span className="font-bold">{children}</span>,
    underline: ({ children }) => <span className="underline">{children}</span>,
    em: ({ children }) => <span className="italic">{children}</span>,
    superscript: ({ children }) => <sup>{children}</sup>,
    subscript: ({ children }) => <sub>{children}</sub>,
    highlight: ({ children }) => (
      <span className="bg-yellow-200 px-1 leading-none">{children}</span>
    ),
    'strike-through': ({ children }) => (
      <span className="line-through">{children}</span>
    ),
  },
};

export { customerStorySectionText, customerStoryBlockText };
