import React from 'react';
import GradientText from '../common/gradient-text';
import Container from '../common/container';
import Fade from '@/components/common/fade';
import { Link } from 'gatsby';

const CustomerStoryBreadcrumbs = ({ pageTitle }) => {
  return (
    <div className="mx-auto max-w-[1092px] px-4 pt-12 lg:pt-20">
      <Fade>
        <div className="item-start -mx-1 flex justify-start text-grey-700">
          <div className="w-auto px-1">
            <Link to="/" aria-label="Galileo Home">
              Home
            </Link>
          </div>
          <div className="w-auto px-1">{'›'}</div>
          <div className="w-auto px-1">
            <Link to="/customer-stories/">Customer Stories</Link>
          </div>
          <div className="w-auto px-1">{'›'}</div>
          <div className="w-auto px-1 capitalize">
            <GradientText>{pageTitle}</GradientText>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default CustomerStoryBreadcrumbs;
