import React from 'react';
import Container from '@/components/common/container';
import Layout from '@/components/common/layout';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PortableText, toPlainText } from '@portabletext/react';
import { getGatsbyImageData } from 'gatsby-source-sanity';

import GradientText from '@/components/common/gradient-text';
import YouTube from 'react-youtube';
import { coldarkDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import sanityConfig from '@/utils/sanity-config';
import { readingTime } from 'reading-time-estimator';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import CustomerStoryBreadcrumbs from '@/components/customer-stories/breadcrumbs';
import CustomerStoryLogoBar from '@/components/customer-stories/logo-bar';
import CustomerStoryTitle from '@/components/customer-stories/title';
import CustomerStorySubTitle from '@/components/customer-stories/subtitle';
import CustomerStoryHighLighted from '@/components/customer-stories/highlighted';
import CustomerStoryOverview from '@/components/customer-stories/overview';
import CustomerStorySections from '@/components/customer-stories/sections';
import CustomerStoriesSignup from '@/components/customer-stories/signup';
import ArrowIcon from '@/assets/arrow.svg';
import SEO from '@/components/seo';

const CustomerStory = ({ data }) => {
  const {
    id,
    title,
    subTitle,
    seo,
    company,
    industry,
    blockquote,
    logo,
    customerRep,
    customerStoryBlocks,
    _rawBlockDescription,
    customerStorySections,
    signUpSection,
  } = data.sanityCustomerStories;

  const { nodes } = data.allSanityCustomerStories;

  const storiesIds = nodes.map((story) => story.id);
  const currentIndex = storiesIds.indexOf(id);
  const nextStory = nodes[currentIndex + 1]
    ? nodes[currentIndex + 1].slug.current
    : null;

  return (
    <Layout>
      <div className="relative">
        <div className="overflow-hidden">
          <div className="mx-auto h-full max-w-[1440px] px-4 sm:px-10 lg:px-20 lg:px-4 xl:px-16">
            <div className="-mx-4 flex flex-wrap">
              <div className="px-4 lg:w-1/12"></div>
              <div className="relative z-30 lg:w-10/12">
                <CustomerStoryBreadcrumbs pageTitle={company} />
                <CustomerStoryLogoBar
                  logo={logo.asset.gatsbyImageData}
                  company={company}
                />
                <CustomerStoryTitle title={title} subtitle={subTitle} />
                <CustomerStorySubTitle company={company} industry={industry} />
                <CustomerStoryHighLighted
                  blockquote={blockquote}
                  customerRep={customerRep}
                  customerStoryBlocks={customerStoryBlocks}
                />
                <CustomerStoryOverview overview={_rawBlockDescription} />
                <CustomerStorySections sections={customerStorySections} />
              </div>
              <div className="px-4 lg:w-1/12"></div>
            </div>
          </div>
        </div>
        {nextStory && (
          <div className="relative lg:absolute lg:inset-0">
            <div className="mx-auto h-full max-w-[1440px] px-4 sm:px-10 lg:px-20 lg:px-4 xl:px-16">
              <div className="-mx-4 flex h-full flex-wrap">
                <div className="h-full px-4 lg:w-1/12"></div>
                <div className="h-full px-4 lg:w-10/12"></div>
                <div className="relative z-40 -mb-12 h-full w-full translate-y-10 transform px-4 lg:mb-0 lg:w-1/12 lg:transform-none">
                  <div className="flex flex-row items-center justify-center px-1 lg:sticky lg:top-20 lg:justify-end lg:pt-36">
                    <a
                      href={`/customer-stories/${nextStory}`}
                      className="next-story-link block w-auto shadow-cta hover:shadow-feature"
                    >
                      <div className="box-border rounded-full bg-gradient-to-br from-orangey-yellow via-reddish-magenta to-purply-blue p-0.5 antialiased">
                        <div className="flex flex-row items-center rounded-full bg-white px-2.5 py-2">
                          <GradientText className="next-story whitespace-nowrap text-center">
                            Next Story
                          </GradientText>
                          <div className="flex h-5 w-5 items-center justify-center">
                            <ArrowIcon />
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="mx-auto h-full max-w-[1440px] overflow-hidden px-0 lg:px-20 lg:px-4 xl:px-16">
        <div className="-mx-4 flex flex-wrap">
          <div className="hidden px-4 lg:block lg:w-1/12"></div>
          <div className="w-full lg:w-10/12 lg:px-4">
            <CustomerStoriesSignup data={signUpSection} />
          </div>
          <div className="hidden px-4 lg:block lg:w-1/12"></div>
        </div>
      </div>
    </Layout>
  );
};

export default CustomerStory;

export const query = graphql`
  query CustomerStory($slug: String!) {
    allSanityCustomerStories(sort: { fields: _createdAt, order: DESC }) {
      nodes {
        id
        slug {
          current
        }
        _createdAt(formatString: "MMMM DD YYYY")
      }
    }

    sanityCustomerStories(slug: { current: { eq: $slug } }) {
      id
      mainImage {
        asset {
          gatsbyImageData(width: 1440, fit: SCALE)
        }
      }

      title
      subTitle
      industry
      company
      blockquote
      slug {
        current
      }
      seo {
        metaTitle
        metaDescription
        image {
          asset {
            gatsbyImageData(width: 1280)
            url
            localFile(width: 1280) {
              publicURL
              childImageSharp {
                fixed(width: 1280) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
      }
      logo {
        asset {
          gatsbyImageData
        }
      }
      customerRep {
        image {
          asset {
            gatsbyImageData
          }
        }
        name
        role
      }
      _rawBlockDescription(resolveReferences: { maxDepth: 10 })
      customerStoryBlocks {
        name
        _rawBlockDescription
        blockDescription {
          style
          list
          _rawChildren
        }
      }
      customerStorySections {
        name
        title
        icon {
          asset {
            gatsbyImageData
          }
        }
        _rawIcon
        _rawBlockDescription
      }

      signUpSection {
        sectionCtaLinks {
          ... on SanityExternalLink {
            label
            url
            _type
          }
          ... on SanityInternalLink {
            _type
            isCta
            label
            slug {
              current
            }
          }
        }
        title {
          _key
        }
        _rawTitle(resolveReferences: { maxDepth: 10 })
      }
      # date(formatString: "MMMM DD YYYY")
    }
  }
`;

export const Head = ({ data }) => {
  const { seo } = data.sanityCustomerStories;
  return (
    <SEO
      title={seo.metaTitle}
      description={seo.metaDescription}
      image={seo && seo.image ? seo.image.asset.localFile.publicURL : null}
      imageWidth={
        seo && seo.image
          ? seo.image.asset.localFile.childImageSharp.fixed.width
          : null
      }
      imageHeight={
        seo && seo.image
          ? seo.image.asset.localFile.childImageSharp.fixed.height
          : null
      }
    />
  );
};
