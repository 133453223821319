import React from 'react';
import Fade from '@/components/common/fade';
import Container from '../common/container';

const CustomerStoryTitle = ({ title, subtitle }) => {
  return (
    <div className="mx-auto max-w-[1092px] px-4 pt-12 lg:pt-14">
      <Fade>
        <div className="">
          <h1 className="font-inter text-4xl font-semibold leading-tight md:text-[48px] lg:text-[56px]">
            {title}
          </h1>
          {subtitle && (
            <p className="mt-5 font-light md:text-lg lg:mt-8">{subtitle}</p>
          )}
        </div>
      </Fade>
    </div>
  );
};

export default CustomerStoryTitle;
