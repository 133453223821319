import React from 'react';
import Fade from '@/components/common/fade';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PortableText } from '@portabletext/react';
import GradientText from '../common/gradient-text';
import { customerStorySectionText } from './customer-story-text';

const Section = ({ section, isLast }) => {
  return (
    <div className="mx-auto max-w-[1092px] px-4">
      <Fade>
        <div className="item-center -mx-4 flex flex-wrap justify-between">
          <div className="w-full px-4 md:w-4/12">
            <div className="mb-4 h-10 w-10">
              <GatsbyImage
                alt={section.name}
                image={section.icon.asset.gatsbyImageData}
              />
            </div>
            <h3 className="mb-8 lg:mb-10">
              <GradientText className="text-xl font-bold">
                {section.name}
              </GradientText>
            </h3>
            <p className="mb-8 text-grey-500 xl:pr-20">{section.title}</p>
          </div>
          <div className="-mb-3 w-full px-4 text-grey-700 md:w-8/12">
            <PortableText
              value={section._rawBlockDescription}
              components={customerStorySectionText as any}
            />
          </div>
        </div>
      </Fade>
      {!isLast && (
        <div className="my-16 h-px bg-gradient-to-r from-y to-pb to-rm lg:my-24" />
      )}
    </div>
  );
};

const CustomerStorySections = ({ sections }) => {
  return (
    <div className="mx-auto max-w-[1280px] rounded-xl bg-white px-4 py-16 lg:py-24">
      {sections &&
        sections.map((section, idx, arr) => {
          if (idx == arr.length - 1) {
            return <Section section={section} isLast={true} />;
          }

          return <Section section={section} isLast={false} />;
        })}
    </div>
  );
};

export default CustomerStorySections;
