import React from 'react';
import Fade from '@/components/common/fade';
import { PortableText } from '@portabletext/react';
import GradientText from '../common/gradient-text';
import { customerStorySectionText } from './customer-story-text';
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics';

const overviewPortableText = {
  marks: {
    link: ({ children, value }) => {
      const target = (value?.href || '').startsWith('http')
        ? '_blank'
        : undefined;
      return (
        <OutboundLink
          href={value.href}
          target={target}
          rel={target === '_blank' && 'noindex'}
          className="text-primary-700 transition-all duration-300 hover:text-purply-blue hover:underline"
        >
          {children}
        </OutboundLink>
      );
    },
    strong: ({ children }) => <span className="font-bold">{children}</span>,
  },
};

const CustomerStoryOverview = ({ overview }) => {
  return (
    <div className="mx-auto max-w-[1092px] px-4 pb-16 pt-32 lg:pb-24 lg:pt-32 xl:pt-40">
      <Fade>
        <div className="item-center -mx-4 flex flex-wrap justify-between">
          <div className="mb-6 w-full px-4 md:w-4/12">
            <div className="-mx4 flex flex-row">
              <GradientText className="text-xl font-bold">
                Overview
              </GradientText>
              <div className="flex w-auto flex-row items-center px-4">
                <div className="box-border rounded-full bg-gradient-to-br from-y via-rm to-pb p-0.5 antialiased">
                  <div className="flex flex-row items-center rounded-full bg-white px-2 py-2">
                    <div className="flex h-3 w-3 items-center justify-center">
                      <GradientText>→</GradientText>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="-mb-3 w-full px-4 text-grey-700 md:w-8/12">
            <PortableText
              value={overview}
              components={customerStorySectionText as any}
            />
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default CustomerStoryOverview;
