import React from 'react';
import GradientText from '../common/gradient-text';
import Container from '../common/container';
import Fade from '@/components/common/fade';

const CustomerStorySubTitle = ({ company, industry }) => {
  return (
    <div className="mx-auto max-w-[1092px] px-4 pt-12 lg:pt-16">
      <Fade>
        <div className="item-start -mx-4 flex justify-start text-sm text-grey-600">
          <div className="w-auto px-4">
            <GradientText className="bg-gradient-to-br from-y via-rm to-pb font-bold">
              Comapany:
            </GradientText>{' '}
            {company}
          </div>
          <div className="w-auto px-4">
            <GradientText className="bg-gradient-to-br from-y via-rm to-pb font-bold">
              Industry:
            </GradientText>{' '}
            {industry}
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default CustomerStorySubTitle;
