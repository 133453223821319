import React from 'react';
import GradientText from '../common/gradient-text';
import Container from '../common/container';
import { GatsbyImage } from 'gatsby-plugin-image';
import Fade from '@/components/common/fade';

const CustomerStoryLogoBar = ({ logo, company }) => {
  return (
    <div className="mx-auto max-w-[1092px] px-4 pt-2 lg:pt-4">
      <Fade>
        <div className="item-center -mx-1 flex justify-between text-grey-700">
          <div className="w-1/2 md:w-auto">
            <GatsbyImage alt={company} image={logo} />
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default CustomerStoryLogoBar;
